import React, { Component } from "react";
import FormComponent from "../component/FormComponent"
class FormDashboard extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="container">
        <div className="sub-content">
         <FormComponent />
         </div>
      </div>
    );
  }
}

export default FormDashboard;
