import React, { Component } from "react";
import "./AlertCss.css";

export class AlertMessageSuccess extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="">
        <div className="alert-message-div animate">
          <div
            className="alert alert-styles"
            show={this.props.show}
            color="white"
          >
            <p className="text-right m-0">
              <i
                class="fa fa-times-circle close-icon alert-success-heading-txt"
                onClick={this.props.onClose}
              ></i>
            </p>
            <div className="d-flex">
              <img
                id="imgSuccess"
                src="../img/success.gif"
                aria-hidden="true"
                className="alert-img-width"
              />
              <p className="font-size-13 m-b">
                <span className="font-size-14 alert-success-heading-txt"> Success! </span>
                <br />
                {this.props.message}
                <br/>
                {this.props.subMessage ? <span>{this.props.subMessage}</span>:""}
               

              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlertMessageSuccess;
