import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import AlertError from "../component/AlertComponent/AlertError";
import AlertSuccess from "../component/AlertComponent/AlertSuccess";
import { FilePicker } from 'react-file-picker'
const options = [
  { value: 0, label: "Query" },
  { value: 1, label: "Access" }
];

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      email: "",
      selectedOpt: null,
      showSuccess: false,
      showError: false,
      selectedType: "Issue Type",
      name: "",
      fileObj:null,
    };
  }
  componentDidMount() { }
  onChangeHandler = val => {
    this.setState({
      selectedOpt: 0,
      selectedType: val
    });
  };
  onEmailChange = val => {
    console.log("email", val);
    this.setState({
      email: val
    });
  };

  onNameChange = val => {
    console.log("name", val);
    this.setState({
      name: val
    });
  };
  setfile(e){
    this.setState({
      fileObj: e.target.files[0]
    });
  }
  // setfile = val => {
  //   this.setState({
  //     fileObj: val
  //   });
  // };
  onMessageChange = val => {
    console.log("email", val);
    this.setState({
      message: val
    });
  };
  onClickSendDetails = e => {
    e.preventDefault();
    this.setState({ showSuccess: false });
    const formData = new FormData(); 
     
    // Update the formData object 
    
   
    if (
      this.state.message &&
      this.state.selectedType != "Issue Type" &&
      this.state.email && this.state.name
    ) {
      axios
        .post(
          " https://corona-dashboard.iaproducts.ai/single2",
          {
            // name:this.state.name,
            type: this.state.selectedType,
            query: this.state.message,
            email: this.state.email,

          }
        )
        .then(
          function (response) {
            console.log("response is", response.data);
            if(this.state.fileObj){
              formData.append( 
                "id",
                response.data.data.id,
                
              )
              formData.append("file", 
              this.state.fileObj)
            }
            
            console.log('moh',this.state.fileObj)
            {this.state.fileObj?(axios
              ({
                method: 'post',
                url: 'https://corona-dashboard.iaproducts.ai/single',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data' }
              })
              // .post(
              //   " https://corona-dashboard.iaproducts.ai/single",
              //   formData, request_config
              // )
              .then(
                function (response) {
                  console.log("response is", response);
                  this.setState({ showSuccess: true })
                  // console.log("response is", this.state.fileObj.type);
                }.bind(this)
              )
              
              .catch(function (error) {
                console.log(error);
                
              }) ):( this.setState({ showSuccess: true }))}
           
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
          // this.setState({
          //   showError: true
          // })
        });
    } else {
      this.setState({
        showError: true
      });
    }
  };

  onCloseAlert = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      selectedOpt: null,
      message: "",
      email: "",
      selectedType: "Select Type",
      name: ""
    });
  };
  selectOptionChange = vals => {
    console.log("nksjd", vals);
  };

  render() {
    return (
      <div className="">
        <img src="../img/PSP Logo.png" id="imgwidth" />
        <div className="form-main-div">
          <div className="card form-card ">
            <h4 className="font-20">PSP PromoSmart Support </h4>
            <div className="form-group">
              <label
                className="font-styling-1"
              >
                Name
                </label>
              <input
                type="text"
                className="form-control gap-Class"
                id="name"
                autocomplete="off"
                placeholder="Name"
                value={this.state.name}
                onChange={e => this.onNameChange(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleInputEmail1"
                className="font-styling-1"
              >
                Email
                </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                value={this.state.email}
                onChange={e => this.onEmailChange(e.target.value)}
              />
            </div>

            <label htmlFor="exampleInputEmail1" className="font-styling-1">
              Issue Type
            </label>
            {/* <Select
              options={options}
              //value={this.state.selectedOpt}
              onChange={val => this.onChangeHandler(val)}
            /> */}
            <nav className="navbar navbar-expand-md">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav" id="main-list">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href=""
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.selectedType}
                    </a>
                    <ul
                      className="dropdown-menu main-menue"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li
                        onClick={() =>
                          this.onChangeHandler("Portal connection issue")
                        }
                      >
                        <span className="dropdown-item item-style">
                          Portal connection issue
                        </span>
                      </li>
                      <li
                        onClick={() => this.onChangeHandler("Portal is slow")}
                      >
                        <span className="dropdown-item item-style">
                          Portal is slow
                        </span>
                      </li>
                      <li className="dropdown-submenu">
                        <span className="dropdown-item dropdown-toggle item-style">
                          Data field issue
                        </span>
                        <ul className="dropdown-menu">
                          <li
                            onClick={() =>
                              this.onChangeHandler("Data field issue: Field is blank")
                            }
                          >
                            <span className="dropdown-item item-style">
                              Field is blank
                            </span>
                          </li>
                          <li
                            onClick={() =>
                              this.onChangeHandler("Data field issue: Field does not match DAX")
                            }
                          >
                            <span className="dropdown-item item-style">
                              Field does not match DAX
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li
                        onClick={() =>
                          this.onChangeHandler(
                            "Revert the portal back to a specific date"
                          )
                        }
                      >
                        <span className="dropdown-item item-style">
                          Revert the portal back to a specific date
                        </span>
                      </li>

                      <li className="dropdown-submenu">
                        <span className="dropdown-item dropdown-toggle item-style">
                          Create a new user from
                        </span>
                        <ul className="dropdown-menu">
                          <li className="dropdown-submenu">
                            <span className="dropdown-item dropdown-toggle item-style">
                              Existing account
                            </span>
                            <ul className="dropdown-menu">
                              <li
                                onClick={() => this.onChangeHandler("Create a new user from: Existing account: Merchant")}
                              >
                                <span className="dropdown-item item-style">
                                  Merchant
                                </span>
                              </li>
                              <li
                                onClick={() => this.onChangeHandler("Create a new user from: Existing account: Vendor")}
                              >
                                <span className="dropdown-item item-style">
                                  Vendor
                                </span>
                              </li>
                              <li
                                onClick={() =>
                                  this.onChangeHandler("Create a new user from: Existing account: Super-user")
                                }
                              >
                                <span className="dropdown-item item-style">
                                  Super-user
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-submenu">
                            <span className="dropdown-item dropdown-toggle item-style">
                              Completely new
                            </span>
                            <ul className="dropdown-menu">
                              <li
                                onClick={() => this.onChangeHandler("Create a new user from: Completely new: Merchant")}
                              >
                                <span className="dropdown-item item-style">
                                  Merchant
                                </span>
                              </li>
                              <li
                                onClick={() => this.onChangeHandler("Create a new user from: Completely new: Vendor")}
                              >
                                <span className="dropdown-item item-style">
                                  Vendor
                                </span>
                              </li>
                              <li
                                onClick={() =>
                                  this.onChangeHandler("Create a new user from: Completely new: Super-user")
                                }
                              >
                                <span className="dropdown-item item-style">
                                  Super-user
                                </span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown-submenu">
                        <span className="dropdown-item dropdown-toggle item-style">
                          Re-map the user - to enter in the description clearly
                        </span>
                        <ul className="dropdown-menu">
                          <li onClick={() => this.onChangeHandler("Re-map the user - to enter in the description clearly: Merchant")}>
                            <span className="dropdown-item item-style">
                              Merchant
                            </span>
                          </li>
                          <li onClick={() => this.onChangeHandler("Re-map the user - to enter in the description clearly: Vendor")}>
                            <span className="dropdown-item item-style">
                              Vendor
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li
                        onClick={() =>
                          this.onChangeHandler("Data on screen issue")
                        }
                      >
                        <span className="dropdown-item item-style">
                          Data on screen issue
                        </span>
                      </li>
                      <li
                        onClick={() =>
                          this.onChangeHandler(
                            "Issue with file upload/Error message"
                          )
                        }
                      >
                        <span className="dropdown-item item-style">
                          Issue with file upload/Error message
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>

            <form>
              <div className="form-group">
                <label htmlFor="msg" className="font-styling-1 m-top">
                  Message
                </label>
                <br />
                <textarea
                  placeholder="Type your query..."
                  name="msg"
                  required
                  value={this.state.message}
                  onChange={e => this.onMessageChange(e.target.value)}
                ></textarea>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="btn btn-animation btn-primary"
                    onClick={e => this.onClickSendDetails(e)}
                  >
                    <span className="global-text-size">Submit </span>
                  </button></div>
                <div className="col-md-6">
                <input type="file" name="file" onChange={e => this.setfile(e)}></input>
                  {/* <FilePicker
                    extensions={['jpg', 'jpeg', 'png','txt']}
                    onChange={FileObject => {if(FileObject){( this.setState({ fileObj: FileObject }))}}}
                    onError={errMsg => (console.log("as",errMsg))}
                      >
                      
                      <button className="">
                      <img src="./img/attachment.png" alt="Insert Attachment" />
    </button>
  </FilePicker> */}
  
              </div>


              </div>

              {this.state.showSuccess ? (
                <AlertSuccess
                  show={this.state.showSuccess}
                  message="Thank you for raising this issue."
                  subMessage=" Please check you email for further details."
                  onClose={this.onCloseAlert}
                />
              ) : null}
              {this.state.showError ? (
                <AlertError
                  show={this.state.showError}
                  message="All fields are required* "
                  subMessage=""
                  onClose={this.onCloseAlert}
                />
              ) : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default FormComponent;
